/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useEffect, createRef } from "react"
import PropTypes from "prop-types"
import MagicGrid from "magic-grid"
import { useIntl } from "gatsby-plugin-intl"

import useWindowSize from "utils/useWindowSize"
import getProjectTimelineReportType from "utils/getProjectTimelineReportType"
import formatDate from "utils/formatDate"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"
import paths from "utils/paths"

import H from "components/atoms/H"
import P from "components/atoms/P"
import Select from "components/atoms/Select"
import Card from "components/atoms/Card"
import BtnLink from "components/atoms/BtnLink"
import Button from "components/atoms/Button"

import theme from "styles/theme"

import {
  filterButtonStyles,
  linkButtonStyles,
  filterInputStyles,
  filterMenuStyles,
  filterMenuItemStyles,
  StyledSection,
  StyledFilterHeader,
  StyledListWrapper,
  StyledTextWrapper,
  CardWrapper,
  titleLinkStyles,
  StyledNoResultsWrapper,
  noResultsTextStyles,
  StyledBlocksImage,
  loadMoreButtonStyles,
} from "./ProjectTimeline.styles"

import VideoReportThumbnail from "./VideoReportThumbnail"
import UpdateReportThumbnail from "./UpdateReportThumbnail"
import QuarterlyReportThumbnail from "./QuarterlyReportThumbnail"

const ProjectTimeline = ({ sectionData }) => {
  const [selectedReportType, setSelectedReportType] = useState("ALL")
  const [visibleReports, setVisibleReports] = useState([])
  const [tempData, setTempData] = useState([])
  const size = useWindowSize()
  const cardList = createRef()
  const { formatMessage } = useIntl()

  const getReportTypes = () => {
    if (sectionData) {
      return sectionData
        .map((element) => element.category)
        .filter((value, index, self) => self.indexOf(value) === index)
    }
    return []
  }

  const reportTypesOptions = () => {
    const reportTypes = getReportTypes().map((type) => ({
      label: getProjectTimelineReportType(type),
      value: type,
    }))
    return [{ label: "Everything", value: "ALL" }, ...reportTypes]
  }

  const filteredData = () =>
    sectionData.filter(
      (item) =>
        selectedReportType === "ALL" || item.category === selectedReportType
    )

  useEffect(() => {
    setTempData(filteredData())
  }, [sectionData, selectedReportType])

  useEffect(() => {
    setVisibleReports(tempData.splice(0, 10))
  }, [tempData])

  const loadMore = () => {
    setVisibleReports([...visibleReports, ...tempData.splice(0, 10)])
  }

  useEffect(() => {
    if (sectionData) {
      const magicGrid = new MagicGrid({
        container: cardList.current,
        static: true,
        maxColumns: 2,
        gutter: 16,
        useMin: true,
        animate: true,
      })
      magicGrid.listen()
    }
  })

  const getReportType = (category) =>
    reportTypesOptions().find((type) => type.value === category)

  const elementDescription = (element) => {
    const descriptionContent = {
      VIDEO_REPORT: element.videoReportPost && element.videoReportPost.body,
      UPDATE: element.updatePost && element.updatePost.body,
      QUARTERLY_REPORT:
        element.quarterlyReportPost &&
        element.quarterlyReportPost.welcomeOpenNote,
    }

    return descriptionContent[element.category] || ""
  }

  const elementThumbnail = (element) => {
    const thumbnailComponents = {
      VIDEO_REPORT: <VideoReportThumbnail video={element} />,
      UPDATE:
        element.updatePost && element.updatePost.mediaType === "VIDEO" ? (
          <VideoReportThumbnail video={element} />
        ) : (
          <UpdateReportThumbnail update={element} />
        ),
      QUARTERLY_REPORT: <QuarterlyReportThumbnail quarter={element} />,
    }
    return thumbnailComponents[element.category] || []
  }

  const handleFilterChange = (select) => {
    setSelectedReportType(select.value)

    trackCustomEventTrigger({
      category: trackingCategoriesHelper.PROJECT_TIMELINE,
      action: "Click",
      label: `${trackingCategoriesHelper.PROJECT_TIMELINE} filter(${select.value})`,
    })
  }

  return (
    <StyledSection>
      <StyledFilterHeader data-testid="timelineFilter">
        {size.width >= theme.breakpoints.medium ? (
          <>
            <span>
              {formatMessage({
                id: "pages.portfolio.project.timeline.show",
              })}
            </span>
            {reportTypesOptions().map((filter) => (
              <Button
                extraStyles={filterButtonStyles}
                type="tertiary"
                key={filter.label}
                onClick={() => handleFilterChange(filter)}
                isActive={selectedReportType === filter.value}
                data-testid="timelineDesktopFilterButton"
              >
                {filter.label}
              </Button>
            ))}
          </>
        ) : (
          <Select
            items={reportTypesOptions()}
            handleOnChange={handleFilterChange}
            extraInputStyles={filterInputStyles}
            extraMenuStyles={filterMenuStyles}
            extraMenuItemStyles={filterMenuItemStyles}
            labelPrefix={formatMessage({
              id: "pages.portfolio.project.timeline.show",
            })}
            testId="timelineMobileFilterSelect"
          />
        )}
      </StyledFilterHeader>
      {visibleReports && (
        <StyledListWrapper ref={cardList} data-testid="timelineList">
          {visibleReports.length !== 0 ? (
            <>
              {visibleReports.map((element) => (
                <CardWrapper key={`${element.title}${element.publicationDate}`}>
                  <Card
                    testId={`timelineCard${
                      getReportType(element.category).value
                    }`}
                  >
                    <Card.Header>
                      <span>{getReportType(element.category).label}</span>
                      <span>
                        {formatDate(
                          element.publicationDate,
                          element.publicationDateDisplaySetting
                        )}
                      </span>
                    </Card.Header>
                    <Card.Thumbnail>
                      <BtnLink
                        to={paths.portfolioProjectTimelinePost(element.slug)}
                        state={{
                          modal: true,
                        }}
                        onClick={() =>
                          trackCustomEventTrigger({
                            category: trackingCategoriesHelper.PROJECT_TIMELINE,
                            action: "Click",
                            label: `${trackingCategoriesHelper.PROJECT_TIMELINE} post via(image) link(${element.title})`,
                          })
                        }
                      >
                        {elementThumbnail(element)}
                      </BtnLink>
                    </Card.Thumbnail>
                    <Card.Title>
                      <BtnLink
                        extraStyles={titleLinkStyles}
                        to={paths.portfolioProjectTimelinePost(element.slug)}
                        state={{
                          modal: true,
                        }}
                        onClick={() =>
                          trackCustomEventTrigger({
                            category: trackingCategoriesHelper.PROJECT_TIMELINE,
                            action: "Click",
                            label: `${trackingCategoriesHelper.PROJECT_TIMELINE} post via(title) link(${element.title})`,
                          })
                        }
                      >
                        {element.title}
                      </BtnLink>
                    </Card.Title>
                    <Card.Description>
                      <StyledTextWrapper>
                        <P>{elementDescription(element)}</P>
                      </StyledTextWrapper>
                      <BtnLink
                        extraStyles={linkButtonStyles}
                        to={paths.portfolioProjectTimelinePost(element.slug)}
                        data-testid="postLink"
                        state={{
                          modal: true,
                        }}
                        onClick={() =>
                          trackCustomEventTrigger({
                            category: trackingCategoriesHelper.PROJECT_TIMELINE,
                            action: "Click",
                            label: `${trackingCategoriesHelper.PROJECT_TIMELINE} post via(read more) link(${element.title})`,
                          })
                        }
                      >
                        {formatMessage({
                          id: "readMore",
                        })}
                      </BtnLink>
                    </Card.Description>
                  </Card>
                </CardWrapper>
              ))}
            </>
          ) : (
            <StyledNoResultsWrapper>
              <H as="h3">
                {formatMessage({
                  id: "pages.portfolio.project.timeline.emptyPostsList.title",
                })}
              </H>
              <P extraStyles={noResultsTextStyles}>
                {formatMessage({
                  id: "pages.portfolio.project.timeline.emptyPostsList.text",
                })}
              </P>
              <StyledBlocksImage />
            </StyledNoResultsWrapper>
          )}
        </StyledListWrapper>
      )}
      {Boolean(tempData.length) && (
        <Button
          onClick={loadMore}
          data-testid="loadMoreButton"
          extraStyles={loadMoreButtonStyles}
          trackingOptions={{
            category: trackingCategoriesHelper.BLOG,
            action: "Click",
            label: `${trackingCategoriesHelper.BLOG} button(Show more)`,
          }}
        >
          {formatMessage({ id: "pages.portfolio.project.timeline.loadMore" })}
        </Button>
      )}
    </StyledSection>
  )
}

ProjectTimeline.propTypes = {
  sectionData: PropTypes.any.isRequired,
}

export default ProjectTimeline
