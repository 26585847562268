import { useIntl } from "gatsby-plugin-intl"

const getProjectTimelineReportType = (type) => {
  const { formatMessage } = useIntl()

  const typeMap = {
    UPDATE: "update",
    VIDEO_REPORT: "videoReport",
    QUARTERLY_REPORT: "quarterlyReport",
  }
  return formatMessage({
    id: `reportTypes.${typeMap[type]}`,
  })
}

export default getProjectTimelineReportType
