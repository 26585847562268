import React from "react"
import PropTypes from "prop-types"
import Q1 from "assets/images/q1.svg"
import Q2 from "assets/images/q2.svg"
import Q3 from "assets/images/q3.svg"
import Q4 from "assets/images/q4.svg"

import { StyledImageWrapper } from "./QuarterlyReportThumbnail.styles"

const QuarterlyReportThumbnail = ({ quarter }) => {
  const { quarterlyReportPost } = quarter

  const elementQuarter = (quarterlyReport) => {
    const quarterComponents = {
      Q1: <Q1 />,
      Q2: <Q2 />,
      Q3: <Q3 />,
      Q4: <Q4 />,
    }

    return quarterComponents[quarterlyReport] || []
  }

  return (
    <StyledImageWrapper>
      {elementQuarter(quarterlyReportPost.reportingQuarter)}
    </StyledImageWrapper>
  )
}

QuarterlyReportThumbnail.propTypes = {
  quarter: PropTypes.shape({
    quarterlyReportPost: PropTypes.shape({
      reportingQuarter: PropTypes.string,
    }),
  }).isRequired,
}

export default QuarterlyReportThumbnail
