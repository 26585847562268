import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { isEmptyRedirect } from "utils/redirect"
import SEO from "components/molecules/SEO"
import Layout from "components/molecules/Layout"
import PortfolioNavigation from "components/molecules/PortfolioNavigation"
import ProjectTimeline from "components/content/Portfolio/ProjectTimeline"
import ClientOnly from "components/atoms/ClientOnly"

const PortfolioTimelinePage = ({
  data: {
    api: { project },
  },
  pageContext: { language },
  location,
}) => {
  const { timelinePosts } = project || {}
  isEmptyRedirect(timelinePosts)

  return (
    <Layout hideFooter isNavStatic language={language}>
      <SEO title="Project timeline" />
      {timelinePosts && (
        <ClientOnly>
          <PortfolioNavigation projectData={project} location={location} />
          <ProjectTimeline sectionData={timelinePosts} language={language} />
        </ClientOnly>
      )}
    </Layout>
  )
}

PortfolioTimelinePage.propTypes = {
  data: PropTypes.shape({
    api: PropTypes.shape({
      project: PropTypes.shape({
        timelinePosts: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      selectedPropertyType: PropTypes.string,
      selectedProjectType: PropTypes.string,
    }),
  }).isRequired,
}

export const query = graphql`
  query ProjectTimelinePageData($apiLocale: API_Locale, $projectSlug: String!) {
    api {
      project(slug: $projectSlug, language: $apiLocale) {
        id
        slug
        name
        timelinePosts(language: $apiLocale, category: "") {
          category
          isPublished
          title
          slug
          publicationDate
          publicationDateDisplaySetting
          videoReportPost {
            body
            transcription
            videoUrl
            videoThumbnail
          }
          updatePost {
            body
            mediaType
            video {
              videoThumbnail
              videoUrl
            }
            images {
              caption
              file
              mediaType
              title
            }
          }
          quarterlyReportPost {
            nextSteps
            projectUpdate
            reportingEndDate
            reportingQuarter
            reportingStartDate
            seePhotosUpdate
            seeProgressDisplay
            signOffNote
            signOffNoteAuthor
            signOffNoteAuthorSignature
            signOffNoteAuthorTitle
            videoUpdateDisplay
            welcomeClosingNote
            welcomeClosingNoteAuthor
            welcomeClosingNoteAuthorSignature
            welcomeClosingNoteAuthorTitle
            welcomeImage
            welcomeOpenNote

            stages {
              id
              milestones {
                body
                completionDate
                completionDateDisplaySetting
                position
                title
                status
                stage {
                  status
                }
              }
              stage {
                description
                name
              }
            }
            seeVideoUpdates {
              videoUrl
              videoThumbnail
            }
            seePhotos {
              file
              id
              position
            }
          }
        }

        ...projectTabs
      }
    }
  }
`

export default PortfolioTimelinePage
