import styled, { css } from "styled-components"
import { transparentize } from "polished"
import Image from "components/atoms/Image"

const StyledImageGrid = styled.div`
  display: grid;
  grid-gap: 1.2rem;
  position: relative;
  width: 100%;
  height: 20.8rem;

  cursor: pointer;

  ${({ imageNumber }) =>
    imageNumber === 2 &&
    css`
      grid-template-columns: repeat(2, 1fr);
    `}

  ${({ imageNumber }) =>
    imageNumber === 3 &&
    css`
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 12rem 1fr;

      & div:first-child {
        grid-column: span 2;
      }
    `}

  ${({ imageNumber }) =>
    imageNumber === 4 &&
    css`
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    `}

  ${({ imageNumber }) =>
    imageNumber >= 5 &&
    css`
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 12rem 1fr;

      & div {
        grid-column: span 2;
      }

      & div:nth-of-type(1),
      & div:nth-of-type(2) {
        grid-column: span 3;
      }
    `}
`

const StyledImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    transition: transform 0.3s linear !important;
  }

  &:hover img {
    transform: scale(1.1);
  }
`

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  transition: transform 0.3s linear;
`

const StyledImageOverlay = styled.div`
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => transparentize(0.2, theme.color.secondary01)};
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  display: flex;
  justify-content: center;
  align-items: center;
`

export { StyledImageGrid, StyledImageWrapper, StyledImage, StyledImageOverlay }
