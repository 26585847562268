import React from "react"
import PropTypes from "prop-types"
import {
  StyledCardBlock,
  StyledCardHeader,
  StyledCardThumbnailWrapper,
  StyledCardTitle,
  StyledCardDescription,
} from "./Card.styles"

const Card = ({ children, testId }) => (
  <StyledCardBlock data-testid={testId}>{children}</StyledCardBlock>
)

const Header = ({ children }) => <StyledCardHeader>{children}</StyledCardHeader>
Card.Header = Header

const Thumbnail = ({ children }) => (
  <StyledCardThumbnailWrapper>{children}</StyledCardThumbnailWrapper>
)
Card.Thumbnail = Thumbnail

const Title = ({ children }) => <StyledCardTitle>{children}</StyledCardTitle>
Card.Title = Title

const Description = ({ children }) => (
  <StyledCardDescription>{children}</StyledCardDescription>
)
Card.Description = Description

Card.propTypes = {
  children: PropTypes.node.isRequired,
  testId: PropTypes.string.isRequired,
}
Header.propTypes = { children: PropTypes.node.isRequired }
Thumbnail.propTypes = { children: PropTypes.node.isRequired }
Title.propTypes = { children: PropTypes.node.isRequired }
Description.propTypes = { children: PropTypes.node.isRequired }

export default Card
