import styled, { css } from "styled-components"
import { transparentize } from "polished"
import Blocks from "assets/images/blocks.svg"

const StyledBlocksImage = styled(Blocks)`
  width: 37.5rem;
  height: 30.7rem;

  ${({ theme }) => theme.mq.medium} {
    width: 46rem;
    height: 28rem;
  }
`

const filterButtonStyles = css`
  height: 2.4rem;
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  min-height: initial;
  padding: 0 0.8rem;
  margin: 0 0.8rem;
  line-height: 1;

  background: none;
  border-color: transparent;
  color: ${({ theme }) => theme.color.secondary02};

  &:hover {
    color: ${({ theme }) => theme.color.white};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${({ theme }) => theme.color.main03};
      color: ${({ theme }) => theme.color.white};
    `}
`

const linkButtonStyles = css`
  color: ${({ theme }) => theme.color.main03};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  cursor: pointer;
  margin: 0.8rem 0 0 auto;
  display: block;
  width: max-content;

  &:hover,
  &:active {
    text-decoration: underline;
  }
  &:active {
    color: ${({ theme }) => theme.color.main02};
  }
`

const filterInputStyles = css`
  display: flex;
  justify-content: space-between;
  min-width: calc(100vw - 2.3rem);
  border: 0.1rem solid ${({ theme }) => theme.color.secondary04};
  padding: 0.8rem 1.2rem;
  color: ${({ theme }) => theme.color.secondary01};
  font-size: ${({ theme }) => theme.font.size.s};
  font-weight: ${({ theme }) => theme.font.weight.regular};

  ${({ isOpen }) =>
    isOpen &&
    css`
      background: ${({ theme }) => theme.color.secondary01};
      color: ${({ theme }) => theme.color.white};
      border-color: ${({ theme }) => theme.color.secondary01};
    `};
`

const filterMenuStyles = css`
  top: 4rem;
  left: 0;
  bottom: initial;
  z-index: 1;
  box-shadow: 0 2px 8px 0
    ${({ theme }) => transparentize(0.84, theme.color.secondary01)};
`

const filterMenuItemStyles = css`
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.color.main03 : theme.color.secondary01};
`

const StyledSection = styled.section`
  background: ${({ theme }) => theme.color.secondary06};
  width: 100%;
  min-height: calc(100vh - 20rem);
  height: 100%;
  padding-bottom: 9rem;

  ${({ theme }) => theme.mq.medium} {
    padding-bottom: 3.2rem;
  }
`

const StyledFilterHeader = styled.header`
  height: 5.6rem;
  background: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.mq.medium} {
    border-top: 0.1rem solid ${({ theme }) => theme.color.secondary04};
    border-bottom: 0.1rem solid ${({ theme }) => theme.color.secondary04};

    span {
      font-size: ${({ theme }) => theme.font.size.xs};
      margin-right: 0.8rem;
    }
  }
`

const StyledListWrapper = styled.div`
  width: 100%;
  max-width: 76.8rem;
  padding: ${({ theme }) => theme.layout.mobilePadding} 0;

  ${({ theme }) => theme.mq.medium} {
    margin: 0 auto;
  }
`

const StyledTextWrapper = styled.div`
  position: relative;
  overflow: hidden;
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /* stylelint-enable */
  hyphens: auto;
  text-align: justify;
`

const CardWrapper = styled.div`
  width: 100%;

  ${({ theme }) => theme.mq.medium} {
    max-width: 36.8rem;
    width: 100%;

    &:nth-child(2) {
      padding-top: 7.2rem;
    }
  }
`

const titleLinkStyles = css`
  color: ${({ theme }) => theme.color.secondary01};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  cursor: pointer;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.color.main03};
    text-decoration: underline;
  }
  &:active {
    color: ${({ theme }) => theme.color.main02};
  }
`

const StyledNoResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4.8rem;
`

const noResultsTextStyles = css`
  margin: 1.6rem 0;
`

const loadMoreButtonStyles = css`
  margin: 3.2rem auto;
`

export {
  filterButtonStyles,
  linkButtonStyles,
  filterInputStyles,
  filterMenuStyles,
  filterMenuItemStyles,
  StyledSection,
  StyledFilterHeader,
  StyledListWrapper,
  StyledTextWrapper,
  CardWrapper,
  titleLinkStyles,
  StyledNoResultsWrapper,
  noResultsTextStyles,
  StyledBlocksImage,
  loadMoreButtonStyles,
}
