import React from "react"
import PropTypes from "prop-types"
import getMediaUrl from "utils/getMediaUrl"

import {
  StyledImageWrapper,
  StyledImage,
  StyledImageOverlay,
  StyledPlayButton,
  StyledTriangle,
} from "./VideoReportThumbnail.styles"

const VideoReportThumbnail = ({ video }) => {
  return (
    <StyledImageWrapper>
      <StyledImage
        src={getMediaUrl(
          (video.videoReportPost && video.videoReportPost.videoThumbnail) ||
            (video.updatePost && video.updatePost.video.videoThumbnail)
        )}
        alt={video.title}
      />
      <StyledImageOverlay />
      <StyledPlayButton>
        <StyledTriangle />
      </StyledPlayButton>
    </StyledImageWrapper>
  )
}

VideoReportThumbnail.propTypes = {
  video: PropTypes.shape({
    videoReportPost: PropTypes.shape({
      videoThumbnail: PropTypes.oneOfType([
        PropTypes.shape({
          original: PropTypes.string,
          large: PropTypes.string,
          medium: PropTypes.string,
          small: PropTypes.string,
        }),
        PropTypes.string,
      ]),
    }),
    updatePost: PropTypes.shape({
      video: PropTypes.shape({
        videoThumbnail: PropTypes.oneOfType([
          PropTypes.shape({
            original: PropTypes.string,
            large: PropTypes.string,
            medium: PropTypes.string,
            small: PropTypes.string,
          }),
          PropTypes.string,
        ]),
      }),
    }),
    title: PropTypes.string,
  }).isRequired,
}

export default VideoReportThumbnail
