import styled from "styled-components"
import { transparentize } from "polished"
import Image from "components/atoms/Image"

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

const StyledImageOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: ${({ theme }) => theme.color.secondary01};
  opacity: 0.4;
`

const StyledPlayButton = styled.div`
  width: 7.2rem;
  height: 7.2rem;
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  background: ${({ theme }) => transparentize(0.52, theme.color.secondary01)};

  border-radius: 50%;
  border: 0.2rem solid ${({ theme }) => theme.color.white};
  transition: border-color 0.15s linear;
`

const StyledTriangle = styled.div`
  position: absolute;
  left: 0.6rem;
  top: 50%;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;

  border-left: 1.6rem solid white;

  transition: border-color 0.15s linear;
`

const StyledImageWrapper = styled.div`
  width: 100%;
  height: 20.8rem;
  overflow: hidden;
  position: relative;

  &:hover > div:last-child {
    border-color: ${({ theme }) => theme.color.senary03};
  }

  &:hover > div:last-child div {
    border-left-color: ${({ theme }) => theme.color.senary03};
  }
`

export {
  StyledImageWrapper,
  StyledImage,
  StyledImageOverlay,
  StyledPlayButton,
  StyledTriangle,
}
