import React from "react"
import PropTypes from "prop-types"

import {
  StyledImage,
  StyledImageGrid,
  StyledImageWrapper,
  StyledImageOverlay,
} from "./UpdateReportThumbnail.styles"

const UpdateReportThumbnail = ({ update }) => {
  const {
    updatePost: { images },
  } = update

  const renderImages = () => {
    if (images.length > 5) {
      return (
        <>
          {images.slice(0, 4).map((image) => (
            <StyledImageWrapper key={image.file}>
              <StyledImage src={image.file} alt="" />
            </StyledImageWrapper>
          ))}
          <StyledImageWrapper>
            <StyledImage src={images[0].file} alt="" />
            <StyledImageOverlay>{`+${images.length - 4}`}</StyledImageOverlay>
          </StyledImageWrapper>
        </>
      )
    }

    return images.map((image) => (
      <StyledImageWrapper key={image.file}>
        <StyledImage src={image.file} alt="" />
      </StyledImageWrapper>
    ))
  }

  // TODO BE connection with image optimization
  return (
    <StyledImageGrid imageNumber={images.length}>
      {renderImages()}
    </StyledImageGrid>
  )
}

UpdateReportThumbnail.propTypes = {
  update: PropTypes.shape({
    updatePost: PropTypes.shape({
      images: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }).isRequired,
}

export default UpdateReportThumbnail
