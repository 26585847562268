import styled from "styled-components"

const StyledCardBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: ${({ theme }) => theme.layout.mobilePadding};
  background: ${({ theme }) => theme.color.white};
`

const StyledCardHeader = styled.header`
  display: flex;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.font.size.xs};
  line-height: 1.67;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.color.secondary02};
  padding-bottom: 1.6rem;

  span,
  p {
    font-size: inherit;
    color: inherit;
  }
`

const StyledCardThumbnailWrapper = styled.div`
  width: 100%;

  height: auto;
  overflow: hidden;
  position: relative;
  margin-bottom: 1.6rem;
  cursor: pointer;
`

const StyledCardTitle = styled.span`
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1.75;
  color: ${({ theme }) => theme.color.secondary01};
  margin-bottom: 0.8rem;
`

const StyledCardDescription = styled.div`
  font-size: ${({ theme }) => theme.font.size.s};
  line-height: 2.3rem;
  color: ${({ theme }) => theme.color.secondary01};
  position: relative;

  p,
  span {
    position: relative;

    &:nth-last-of-type(-n + 2) {
      display: block;
    }
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
  }
`

export {
  StyledCardBlock,
  StyledCardHeader,
  StyledCardThumbnailWrapper,
  StyledCardTitle,
  StyledCardDescription,
}
